import dayjs from 'dayjs'
import { useState } from 'react'

import { Arrow } from 'shared/icons'
import { getStartEndRange, useRange } from 'shared/lib/utils'
import { useScheduleStore } from 'shared/model'
import { SmoothNumber } from 'shared/ui/smoothNumber'

import './rangeSwitcher.scss'

export default function CurrentRangeSwitcher() {
	const { setCurrentStartRange } = useScheduleStore(
		({ setCurrentStartRange }) => ({
			setCurrentStartRange,
		})
	)

	const { currentStartRange } = useRange()
	const prevRange = currentStartRange.subtract(1, 'week').isoWeekday(1)
	const nextRange = currentStartRange.add(1, 'week').isoWeekday(1)
	const { start_date, end_date } = getStartEndRange(currentStartRange)
	const [startDate, setStartDate] = useState(start_date.date())
	const [endDate, setEndDate] = useState(end_date.subtract(1, 'day').date())
	const handleDownRange = () => {
		setCurrentStartRange(prevRange)
		setStartDate(dayjs(start_date).subtract(7, 'day').date())
		setEndDate(dayjs(end_date).subtract(8, 'day').date())
	}

	const handleUpRange = () => {
		setCurrentStartRange(nextRange)
		setStartDate(dayjs(start_date).add(7, 'day').date())
		setEndDate(dayjs(end_date).add(6, 'day').date())
	}

	return (
		<div className={'switcher'}>
			<button onClick={handleDownRange} className="switcher__arrow">
				<Arrow isLeft className="switcher__arrow__icon" />
			</button>
			<button
				onClick={() => {
					setCurrentStartRange(dayjs())
					const firstDayOfWeek = dayjs().isoWeekday(1)
					setStartDate(firstDayOfWeek.date())
					setEndDate(firstDayOfWeek.add(6, 'day').date())
				}}
				className="uppercase switcher__date"
			>
				{/* <SmoothNumber
					value={startDate}
					month={dayjs(start_date).format('MMM')}
				/> */}
				<span style={{ paddingInline: '5px' }}>сегодня</span>
				{/* <SmoothNumber
					value={endDate}
					month={dayjs(end_date).subtract(1, 'day').format('MMM')}
				/> */}
			</button>
			<button onClick={handleUpRange} className="switcher__arrow">
				<Arrow className="switcher__arrow__icon" />
			</button>
		</div>
	)
}
