import clsx from 'clsx'
import { FC } from 'react'

import defaultAvt from 'shared/assets/man-3.webp'
import { sortPlayers } from 'shared/lib/utils'
import { useSessionStore } from 'shared/model'
import { IPlayer } from 'shared/types'

import './playerCard.scss'

interface IPlayerCard {
	readonly player: IPlayer
	readonly className?: string
}

export const PlayerCard: FC<IPlayerCard> = ({ player, className }) => {
	const session = useSessionStore((state) => state.session)
	const currentPlayer = session?.id === player.id
	return (
		<article
			className={clsx(
				'player-card',
				className,
				currentPlayer && 'current-player'
			)}
		>
			<img
				src={defaultAvt}
				alt="Аватар игрока"
				className="player-card__avatar"
			/>
			<div className="player-card__info">
				{player.role && <span>{player.role}</span>}
				{currentPlayer ? (
					<span>Вы</span>
				) : (
					<>
						<span className="ellipsis">{player.last_name}</span>
						<span className="ellipsis">{player.first_name}</span>
					</>
				)}
			</div>
		</article>
	)
}
