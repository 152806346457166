import clsx from 'clsx'
import { FC, useEffect, useRef } from 'react'

import { CloseIcon } from 'shared/icons'

import { Logo } from '../logo'

import './modal.scss'

interface IModal {
	readonly children: React.ReactNode
	readonly onClose: () => void
	readonly title?: string
	readonly isHighPriority?: boolean
	readonly closeButton?: boolean
	readonly className?: string
	readonly small?: boolean
}

export const Modal: FC<IModal> = ({
	children,
	onClose,
	closeButton = false,
	className,
	small,
	title,
	isHighPriority,
}) => {
	const modalRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		document.body.style.overflow = 'hidden'
		modalRef.current?.focus()
		return () => {
			document.body.style.overflow = ''
		}
	}, [])
	const handleClose = () => {
		modalRef.current?.classList.add('modal-out')
		setTimeout(() => {
			onClose()
		}, 500)
	}
	return (
		<div
			id={'modal-dialog'}
			ref={modalRef}
			onMouseDown={(e) => {
				if (e.target === modalRef.current) {
					handleClose()
				}
			}}
			onMouseUp={(e) => e.stopPropagation()}
			className={clsx('modal-overlay', isHighPriority && 'high-priority')}
			role="dialog"
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className={clsx('modal-wrapper', className, small && 'small')}
			>
				<div className="modal-wrapper__logo">
					<Logo />
				</div>
				{title && <h5 className="modal-wrapper__title">{title}</h5>}
				<div onClick={(e) => e.stopPropagation()} className="modal-content">
					{children}
				</div>

				{closeButton && (
					<button
						onClick={handleClose}
						aria-label="Закрыть окно"
						className="modal-wrapper__close-btn"
					>
						<CloseIcon />
					</button>
				)}
			</div>
		</div>
	)
}
