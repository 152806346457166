import clsx from 'clsx'
import { ButtonHTMLAttributes, ForwardedRef, forwardRef } from 'react'

import './button.scss'

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
	readonly theme?:
		| 'green'
		| 'transparent-green'
		| 'red'
		| 'yellow'
		| 'yellow-soft'
		| 'black'
		| 'black-green'
		| 'link'
	readonly isLoading?: boolean
	readonly bold?: boolean
	readonly rounded?: boolean
	readonly centered?: boolean
	readonly link?: boolean
	readonly Icon?: React.JSXElementConstructor<React.SVGProps<SVGSVGElement>>
	readonly size?: 'small' | 'medium' | 'large'
}

const ButtonComponent = (
	props: IButton,
	ref: ForwardedRef<HTMLButtonElement>
) => {
	const {
		children,
		className,
		theme = 'black-green',
		type = 'button',
		isLoading,
		bold = false,
		rounded = false,
		centered = false,
		size,
		Icon,
		link,
		...rest
	} = props
	return (
		<button
			type={type}
			{...rest}
			ref={ref}
			className={clsx(
				link ? 'button-link' : 'button',
				`button_theme_${theme}`,
				className,
				isLoading && 'button__loading',
				bold && 'button__bold',
				rounded && 'button__rounded',
				centered && 'button__centered',
				size && `button__${size}`
			)}
		>
			{isLoading ? <span className="button__loader"></span> : children}
			{Icon && <Icon className="button__icon" />}
		</button>
	)
}
export const Button = forwardRef(ButtonComponent)

Button.displayName = 'Button'
