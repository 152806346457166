import clsx from 'clsx'
import { useState } from 'react'
// Components
// Context
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { PopupRegister } from 'widgets/popupRegister'

import { getUserProfileData, login } from 'shared/api/account'
import { IAuthFields } from 'shared/api/account/types'
import { STORE_USER_DATA_NAME } from 'shared/constants'
import { EyeIcon } from 'shared/icons'
import { clearSession, createSession } from 'shared/lib/auth/sessions'
import { handleErrorStatus, toastError } from 'shared/lib/handleError'
import { useSessionStore } from 'shared/model'
import { TPopupAccountMode } from 'shared/types'
import { Button } from 'shared/ui/button'
import { Field } from 'shared/ui/field'
import { Logo } from 'shared/ui/logo'
import { Modal } from 'shared/ui/modal'

// Files
import './PopupAccount.scss'

export const PopupAccount = () => {
	const navigate = useNavigate()
	const [mode, setMode] = useState<TPopupAccountMode>('login')
	const { isPopupAccountOpen, setIsPopupAccountOpen } = useSessionStore(
		({ isPopupAccountOpen, setIsPopupAccountOpen }) => ({
			isPopupAccountOpen,
			setIsPopupAccountOpen,
		})
	)
	/*** Handlers ***/
	const handleClosePopup = () => setIsPopupAccountOpen(!isPopupAccountOpen)
	const { session, setSession } = useSessionStore(
		({ session, setSession }) => ({ session, setSession })
	)

	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
	} = useForm<IAuthFields>()
	const onSubmit = async (values: IAuthFields) => {
		try {
			const tokens = await login(values)
			createSession(tokens)
			setIsPopupAccountOpen(!isPopupAccountOpen)
			const user = await getUserProfileData()
			localStorage.setItem(
				STORE_USER_DATA_NAME,
				JSON.stringify({
					id: user.id,
					sex: user.sex,
					first_name: user.first_name,
					last_name: user.last_name,
				})
			)
			setSession(user)
		} catch (e) {
			if (handleErrorStatus(e) === 401) {
				toast.error('Неверный логин или пароль, попробуйте снова')
			} else {
				toastError(e)
			}
		}
	}
	const getModalTitle = () => {
		switch (mode) {
			case 'login':
				return 'Вход'
			case 'register':
				return 'Регистрация'
			case 'phone-confirm':
				return 'Подтверждение номера'
		}
	}
	const handleChangeMode = (mode: TPopupAccountMode) => {
		setMode(mode)
	}
	const renderLoginForm = () => (
		<div className={clsx('popup-account')} id="login-container">
			<form
				className="popup-account__form"
				onSubmit={handleSubmit(onSubmit)}
				autoComplete="on"
			>
				<Field
					control={control}
					name="username"
					label="Логин"
					autoFocus
					error={errors.username?.message}
					required
					placeholder="Логин"
				/>
				<Field
					control={control}
					name="password"
					type="password"
					label="Пароль"
					error={errors.password?.message}
					required
					placeholder="Пароль"
				/>

				<Button
					theme="yellow"
					type="submit"
					centered
					bold
					className="popup-account__form__btn"
				>
					Войти
				</Button>
				<Button
					centered
					link
					theme="link"
					onClick={() => handleChangeMode('register')}
				>
					Забыли пароль?
				</Button>
				<Button
					centered
					link
					theme="link"
					onClick={() => handleChangeMode('register')}
				>
					У меня нет аккаунта
				</Button>
			</form>
		</div>
	)
	return (
		<Modal
			className="popup-account-modal"
			onClose={handleClosePopup}
			closeButton
			isHighPriority
			title={getModalTitle()}
		>
			{mode === 'login' ? (
				renderLoginForm()
			) : (
				<PopupRegister onChangeMode={handleChangeMode} mode={mode} />
			)}
		</Modal>
	)
}
