import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

import './loaderSchedule.scss'

interface ILoaderSchedule {}

export const LoaderSchedule: FC<ILoaderSchedule> = () => {
	return (
		<div className="loader-schedule">
			<Skeleton className="loader-schedule__skeleton" />
			<Skeleton className="loader-schedule__skeleton" />
		</div>
	)
}
