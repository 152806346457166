import { create } from 'zustand'

import { IUser } from 'shared/api/account'
import { getSession } from 'shared/lib/auth'
import { getRandomAvatar } from 'shared/lib/utils'

interface ISessionStore {
	session: IUser | null
	setSession: (session: IUser | null) => void
	isPopupAccountOpen: boolean
	setIsPopupAccountOpen: (isPopupAccountOpen: boolean) => void
	isPopupRegisterOpen: boolean
	setIsPopupRegisterOpen: (isPopupRegisterOpen: boolean) => void
	defaultAvatar: string
}

export const useSessionStore = create<ISessionStore>()((set, get) => ({
	session: getSession(),
	setSession: (session) => set({ session }),
	isPopupAccountOpen: false,
	setIsPopupAccountOpen: (isPopupAccountOpen) => {
		// const isPopupRegisterOpen = get().isPopupRegisterOpen
		// if (isPopupRegisterOpen && isPopupAccountOpen) {
		// 	set({ isPopupRegisterOpen: false })
		// 	// setTimeout(() => set({ isPopupAccountOpen }), 300)
		// 	// return
		// }

		set({ isPopupAccountOpen })
	},
	isPopupRegisterOpen: false,
	setIsPopupRegisterOpen: (isPopupRegisterOpen) => {
		// const isPopupAccountOpen = get().isPopupAccountOpen
		// if (isPopupAccountOpen && isPopupRegisterOpen) {
		// 	set({ isPopupAccountOpen: false })
		// 	// setTimeout(() => set({ isPopupRegisterOpen }), 300)
		// 	// return
		// }
		set({ isPopupRegisterOpen })
	},
	defaultAvatar: getRandomAvatar(getSession()?.sex ?? null),
}))
