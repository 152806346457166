import { WorkoutDetailFeatures } from './workoutDetailFeatures/workoutFeatures'
import { WorkoutPhoto } from './workoutDetailPhoto/workoutPhoto'
import { WorkoutSquad } from './workoutSquad/workoutSquad'
import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { FC, MouseEvent, TouchEvent, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import { getClassById } from 'shared/api/class'
import { CheckIcon, ClockIcon, ShareIcon, TrainerIcon } from 'shared/icons'
import { getClassTime } from 'shared/lib/getClassTime'
import { useResize } from 'shared/lib/useResize'
import { IconTitle } from 'shared/ui/iconTitle'
import { Interval } from 'shared/ui/interval'
import { LevelInfo } from 'shared/ui/level'
import { LoaderWorkoutDetail } from 'shared/ui/loaders'
import { Tab } from 'shared/ui/tab'

import './workoutDetailModal.scss'

interface IWorkoutDetailModal {
	readonly renderButton: () => React.ReactNode
	readonly classId: number
	readonly date: string
	readonly handleCloseDetail: () => void
}
export const WorkoutDetailModal: FC<IWorkoutDetailModal> = ({
	renderButton,
	classId,
	handleCloseDetail,
	date,
}) => {
	const { data: workout, isLoading } = useQuery({
		queryKey: ['workout', classId],
		queryFn: () => getClassById(classId),
	})
	const tabsKeys = ['details', 'composition', 'photo'] as const
	const tabsNames = {
		details: 'Подробности',
		photo: 'Фото',
		composition: 'Состав',
	}
	type TTabs = (typeof tabsKeys)[number]
	const [activeTab, setActiveTab] = useState<TTabs>('details')
	const handleChangeTab =
		(activeTab: TTabs) => (e: MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation()
			setActiveTab(activeTab)
		}

	const tabs = {
		details: WorkoutDetailFeatures,
		photo: WorkoutPhoto,
		composition: WorkoutSquad,
	}
	const tabsComponents = tabsKeys.map((tab) => {
		const TabComponent = tabs[tab]
		return TabComponent
	})
	const activeTabIndex = tabsKeys.findIndex((tab) => tab === activeTab)
	const { LG_SCREEN } = useResize()
	const hasTouch = matchMedia('(hover: none)').matches
	useEffect(() => {
		document.body.style.overflow = 'hidden'

		return () => {
			document.body.style.overflow = ''
		}
	}, [LG_SCREEN])
	const [isCopiedUrl, setIsCopiedUrl] = useState(false)
	const dayOfTheWeek = dayjs(date).isoWeekday()
	const isOnLeftSide = dayOfTheWeek > 3
	useEffect(() => {
		const contentContainer =
			document.querySelectorAll('.hide-scroll')[activeTabIndex]
		if (contentContainer) {
			contentContainer.scrollTop = 0
		}
	}, [activeTabIndex])

	const [touchPosition, setTouchPosition] = useState<number | null>(null)
	const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
		if (!hasTouch) return
		const touchDown = e.touches[0].clientY
		setTouchPosition(touchDown)
	}

	const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
		if (!hasTouch || touchPosition === null) return
		if (touchPosition > 190) return
		const currentPosition = e.touches[0].clientY
		const diff = currentPosition - touchPosition
		if (diff > 50) {
			handleCloseDetail()
		}
	}

	return (
		<section
			id="workout-detail-modal"
			onTouchStart={handleTouchStart}
			onTouchMove={handleTouchMove}
			className={clsx('workout-detail-modal', workout && 'loaded')}
			data-side={isOnLeftSide ? 'left' : 'right'}
		>
			{isLoading && <LoaderWorkoutDetail />}
			{workout && (
				<div className="workout-detail-modal__wrapper">
					<div className="workout-detail-modal__header">
						<div>
							<h3 className="workout-detail-modal__header__title">
								<span className="capitalize">
									{dayjs(workout.date).format('dddd, ')}
								</span>
								<span>{dayjs(workout.date).format('D MMMM ')}</span>
							</h3>
							<IconTitle
								Icon={ClockIcon}
								title={getClassTime(date, workout.duration)}
								className="workout-detail-modal__header__time"
								gap={12}
							/>
						</div>
						<LevelInfo
							className="workout-detail-modal__header__level"
							levelTitle={`${workout.level.title} уровень`}
							levelColor={workout.level.color}
							is_changed_level={workout.is_changed_level}
						/>
					</div>
					<div className="workout-detail-modal__switcher">
						<div className="workout-detail-modal__switcher__tabs">
							{tabsKeys.map((tab) => (
								<Tab
									key={tab}
									onClick={handleChangeTab(tab)}
									active={activeTab === tab}
								>
									{tabsNames[tab]}
								</Tab>
							))}
						</div>
					</div>
					<div className="workout-detail-modal__content-wrapper">
						<div
							data-active-tab={activeTabIndex}
							className="workout-detail-modal__content"
						>
							{tabsComponents.map((TabComponent, index) => (
								<TabComponent
									key={index}
									workout={workout}
									isActive={index === activeTabIndex}
								/>
							))}
						</div>
					</div>
					<div className="workout-detail-modal__footer">
						<div className="workout-detail-modal__footer__wrapper">
							{renderButton()}
							<button
								onClick={() => {
									const url = window.location.href
									navigator.clipboard.writeText(url)
									setIsCopiedUrl(true)
								}}
								className={clsx(
									'workout-detail-modal__footer__share',
									isCopiedUrl && 'copied'
								)}
							>
								{isCopiedUrl ? <CheckIcon /> : <ShareIcon />}
							</button>
						</div>
					</div>
				</div>
			)}
		</section>
	)
}
